@import "../variables";

:root {
    --primary: #{$congressBlue};
    --primary-light: #{$scienceBlue};
    --primary-dark: #{$prussianBlue};
    --secondary: #{$fernFrond};
    --secondary-light: #{$mantis};
    --secondary-dark: #{$dell};
}
