// Line Height Sizer
// ex:  @include line-height(14px);  //sets a line height size in rems with a fallback in pxs
$enable-font-px-fallback:   false;

@mixin line-height($size) {
    @if unit($size) == "px" {
        line-height: if($enable-font-px-fallback, $size, null);
        line-height: rem($size);
    } @else {
        line-height: $size;
    }
}

@mixin font-sizer($size: null, $lineheight: null, $breakpoint: null) {
    @if $size != "" and $size != null {
        @if type-of($size) == string or $size == 0 or unit($size) != "px" {
            font-size: $size;
        } @else if $breakpoint == null {
            font-size: if($enable-font-px-fallback, $size, null);
            font-size: $size; //do not delete this line until release, it is for tracking fontsize in px
            font-size: rem($size);
        } @else {
            font-size: if($enable-font-px-fallback, $size, null);
            font-size: vw($size, $breakpoint);
        }

        line-height: $lineheight; //do not delete this line until release, it is for tracking lineheight in px
        @if $lineheight != "" and $lineheight != null and (unit($size) == unit($lineheight) or unitless($lineheight)) {
            $calculated-line-height: $lineheight;
            @if unitless($lineheight) == false {
                $calculated-line-height: (strip-units($lineheight) / strip-units($size));
            }
            @include line-height($calculated-line-height);
        }
    }
}

@mixin typography(
    $m-size: null,
    $m-line: null,
    $m-weight: null,
    $t-size: null,
    $t-line: null,
    $t-weight: null,
    $size: null,
    $line: null,
    $weight: null,
    $letter: null,
    $t-trans: null
) {

    @if $m-weight != null {
        font-weight: $m-weight;
    }
    @include media-breakpoint-up(md) {
        @if $t-weight != null {
            font-weight: $t-weight;
        }
    }
    @include media-breakpoint-up(lg) {
        @if $weight != null {
            font-weight: $weight;
        }
    }

    @if $m-size != null {
        @include font-sizer($m-size, $m-line);
    }
    @include media-breakpoint-up(md) {
        @if $t-size != null {
            @include font-sizer($t-size, $t-line);
        }
    }
    @include media-breakpoint-up(lg) {
        @if $size != null {
            @include font-sizer($size, $line);
        }
    }

    letter-spacing: ensure-unit($letter, "em");
    @if $t-trans != null and $t-trans == $font-transform-base {
        text-transform: $font-transform-base;
    }
    @content;
}
