.container .breadcrumb {
    border-radius: 0;
    border-bottom: 0 none;
}

.breadcrumb {
    padding: 0;
    margin-bottom: 40px;

    .breadcrumb-item {
        position: relative;
        letter-spacing: -0.17px;
        font-weight: $font600;
        color: var(--primary);
        margin: 0 0 0 25px;
        @include font-sizer(17px, 1);

        a {
            letter-spacing: -0.17px;
            font-weight: $font600;
            color: var(--primary-light);
            @include font-sizer(17px, 1);
        }

        &::before {
            content: '';
            background: $white;
            float: left;
            margin: 0 8px;
            mask-image: url('../../images/svg/chevronLeft.svg');
            position: absolute;
            left: -23px;
            top: 3px;
            width: 9px;
            height: 13px;
            background-color: var(--primary);
            z-index: 2;
        }

        &:last-of-type {
            &::before {
                content: none;
            }
        }

        + .breadcrumb-item::before {
            padding: 0;
        }
    }
}

.back-button-container {
    a {
        letter-spacing: -0.17px;
        font-weight: $font600;
        color: var(--secondary);
        position: relative;
        margin: 0 15px 0 0;
        text-decoration: underline;
        @include font-sizer(17px, 1);

        &::after {
            content: '';
            background: $white;
            float: left;
            mask-image: url('../../images/svg/chevronLeft.svg');
            position: absolute;
            right: -15px;
            top: 3px;
            width: 9px;
            height: 13px;
            background-color: var(--primary);
            z-index: 2;
        }
    }
}
