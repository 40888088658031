@import "base/utilities/swatch";
@import "../variables";

#searchModal {
    .modal-dialog {
        width: 100%;
        max-width: 800px;
    }

    .modal-content {
        background: $transparent;
        border: 0;
    }

    .modal-body {
        padding: 0;
    }
}

.site-search {
    width: 100%;
    position: relative;
    padding: 0;
    border: $netural-border;
    background-color: $white;
    border-radius: map_get($spacers, 1);

    input {
        width: calc(100% - 65px);
        padding: 12px map_get($spacers, 2);
        border: 0;
        color: var(--primary);
        box-shadow: none;
        @include font-sizer(21px, 21.84px);

        &:focus {
            color: var(--primary);
            outline: none;
            border: 0;
            box-shadow: none;
        }

        &::placeholder {
            color: $netural-color;
            @include font-sizer(21px, 21.84px);
        }
    }

    .fa.svg-icon {
        svg {
            path { // eslint-disable-line
                stroke: $netural-color-second;
            }
        }

        &:hover {
            svg {
                path { // eslint-disable-line
                    stroke: var(--secondary);
                }
            }
        }
    }
}

.suggestions-wrapper {
    position: relative;
}

.suggestions {
    display: block;
    border-radius: map_get($spacers, 1);
    position: absolute;
    border: $netural-border;
    background-color: $white;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 800px;
    z-index: 3;

    .item {
        border-top: $netural-border;

        &:first-child {
            border-top: 0;
        }
    }

    .name {
        color: var(--secondary);
        font-weight: $font600;
        text-decoration: underline;
        @include font-sizer(21px, 21px);

        &:hover {
            text-decoration: none;
        }
    }

    .swatch-circle {
        width: 70px;
        height: 36px;
        object-fit: cover;
    }

    .product-suggestion-description {
        color: var(--primary);
        align-self: center;
        @include font-sizer(17px, 17px);
    }
}

.more-below {
    -moz-border-radius: 1.25em;
    background: $grey3;
    border: 0.063em solid rgba(0, 0, 0, 0.1);
    border-radius: 1.25em;
    bottom: 1.875em;
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.3);
    display: none;
    height: 2.5em;
    position: fixed;
    right: 1.875em;
    width: 2.5em;

    i.fa-long-arrow-down {
        border-radius: 50%;
        color: $white;
        display: table-caption;
        height: 0.75em;
        font-size: 1.5rem;
        left: 0.57em;
        line-height: 0.8em;
        position: absolute;
        top: 0.4em;
        width: 0.8em;
    }
}

.search-form {
    width: calc(100% - 200px);
}

.search-filters {
    width: 200px;
}

.search-filters {
    .selectric {
        height: 46px;
        border-radius: 0 map_get($spacers, 1) map_get($spacers, 1) 0;
        border: 0;
        border-left: $netural-border;
        background-color: $white;

        .button {
            left: 0;
            right: auto;
            background: transparent;

            &::after {
                content: '';
                width: 20px;
                height: 20px;
                position: absolute;
                left: 16px;
                top: 15px;
                mask-image: url('../../images/svg/select-arrow-small.svg');
                display: block;
                background: var(--secondary);
                margin: 0;
                border: 0;
            }
        }

        .label {
            margin: 0;
            padding: $input-padding;
            height: auto;
            color: var(--primary);
            position: relative;
            @include font-sizer(21px, 21.84px);
            @include rtl();
        }
    }

    .selectric-open {
        .button {
            transform: rotate(180deg);

            &::after {
                left: 3px;
                top: 4px;
            }
        }
    }

    .selectric-items {
        border-radius: map_get($spacers, 1);
        overflow: hidden;
        @include media-breakpoint-up(md) {
            width: 367px !important;
            left: auto;
            right: 0;
            padding: map_get($spacers, 1) map_get($spacers, 2);
        }

        li {
            margin-top: map_get($spacers, 1);
            color: var(--primary-dark);
            @include font-sizer(21px, 21.84px);
            @include rtl();

            &:hover,
            &.selected {
                background: $base-light;
                color: var(--primary-dark);
            }
        }
    }
}
