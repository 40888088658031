nav.navbar {
    .menu-group {
        li.nav-item {
            display: inline-block;
            margin: 0 12px;
            @include ltr();
            @include media-breakpoint-down(sm) {
                max-width: 100%;
                width: 100%;
                text-align: right;
                display: block;
                margin: 0 0 map_get($spacers, 3);
            }

            .nav-link {
                color: var(--primary);
                letter-spacing: -0.17px;
                font-weight: $font600;
                transition: color 0.5s ease-in-out;
                @include font-sizer(17px);
                @include rtl();
                @include media-breakpoint-down(sm) {
                    width: 100%;
                    font-weight: $font400;
                    @include font-sizer(21px, 21.84px);
                }

                &:hover {
                    color: var(--secondary);
                }

                .category-mobile-icon {
                    display: none;
                    @include media-breakpoint-down(sm) {
                        display: inline-block;
                        position: relative;
                        width: 28px;
                        top: 5px;
                        margin-left: map_get($spacers, 2);
                        text-align: right;
                    }

                    svg {
                        width: 100%;
                        height: 100%;
                        max-width: 28px;
                        max-height: 28px;
                    }
                }
            }
        }
    }
}

.nav.navbar-nav {
    display: flex;
    flex-direction: column-reverse;

    @include media-breakpoint-up(md) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        text-align: center;
    }
}

.menu-wrapper {
    @include media-breakpoint-down(sm) {
        right: calc(-100% - #{map_get($spacers, 3)});
        top: 0;
        bottom: 0;
        position: fixed;
        display: block;
        width: 350px;
        height: 100vh;
        transition: $slide-out-animation;
        background: $menu-background-color;
        z-index: $z-index-4;
    }

    &.open {
        @include media-breakpoint-down(sm) {
            right: 0;
            overflow: scroll;
            z-index: 100;
        }
    }

    .close-menu {
        width: 20px;
        height: 20px;
    }

    .logo-mobile-asset {
        @include media-breakpoint-down(sm) {
            display: flex;
            justify-content: center;
            border-bottom: 1px solid $shuttleGrey;
        }

        a {
            margin: 0 4px;
        }

        img {
            @include media-breakpoint-down(sm) {
                max-height: 48px;
                vertical-align: top;
            }
        }
    }
}

.logo-wrapper {
    img {
        max-height: 60px;
        max-width: 110px;
        transition: transform 0.25s ease-in-out;

        @include media-breakpoint-down(sm) {
            max-height: 48px;
            width: 100%;
        }

        &:hover {
            transform: scale(1.08);
        }
    }
}

.menu-open {
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;
    overflow: hidden;
    background-color: $menu-background-color;
}

.login-mobile-container {
    svg {
        position: relative;
        top: 4px;
        margin-left: 4px;
    }
}
