@import "base/components/toastMessage";

.notification-messages {
    @include toast-message();

    z-index: 1051;
    @include rtl();
}

.notification-alert {
    @include toast-alert();

    z-index: 1051;
}

@mixin container() {
    margin: 0 auto;
    padding: 0;
    box-sizing: content-box;
}

.container {
    max-width: $max-content-width;

    @include container();
}

.carousel-container {
    @include container();
}

.wide-container {
    max-width: $max-container-width;

    @include container();
}

.content-asset-container {
    padding: 0 map_get($spacers, 2) map_get($spacers, 3);
    color: var(--primary-dark);
    @include font-sizer(17px, 1);

    @include media-breakpoint-up(md) {
        padding: 0 0 map_get($spacers, 5);
        @include font-sizer(21px, 104%);
    }

    img {
        max-width: 100%;
    }

    strong {
        font-weight: $font600;
        line-height: 1;
    }

    ol {
        padding: 0;
        margin: 0 20px 0 0;

        li {
            padding: 0 4px 0 0;

            &::marker {
                padding: 0 0 0 5px;
                font-weight: $font400;
            }
        }
    }

    ul {
        padding: 0;
        margin: 0 20px 0 0;

        li {
            list-style: disc;
            list-style-position: outside;
            padding: 0 4px 0 0;
        }
    }

    p {
        font-weight: $font400;

        &.title-label {
            color: var(--secondary);
            letter-spacing: -0.6px;
            font-weight: $font600;
            margin-top: map_get($spacers, 3);
            @include font-sizer(20px, 1);

            @include media-breakpoint-up(md) {
                margin-top: map_get($spacers, 5);
                @include font-sizer(30px, 1);
            }
        }

        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    p + p {
        margin-top: map_get($spacers, 3);
    }

    .article {
        color: var(--primary);
        @include font-sizer(21px, 104%);
        @include media-breakpoint-up(md) {
            @include font-sizer(17px, 1);
        }

        ul,
        ol {
            margin: map_get($spacers, 3) 20px map_get($spacers, 3) 0;
        }

        .navigator {
            color: $base-light;
            background: $white;
            position: relative;
            margin: 0 auto;
            display: flex;
            width: 353px;
            justify-content: space-between;
            align-items: center;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);

            @include media-breakpoint-up(md) {
                width: 580px;
            }

            a {
                color: var(--primary);
                display: flex;
                width: 80px;
                height: 80px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 7px;
                font-weight: $font600;
                @include font-sizer(9px, 1);

                @include media-breakpoint-up(md) {
                    width: 130px;
                    height: 130px;
                    gap: 12px;
                    @include font-sizer(15px, 1);
                }
            }

            a.fill {
                &:hover {
                    svg {
                        path {
                            fill: $shuttleGrey;
                        }
                    }
                }
            }

            a.stroke {
                &:hover {
                    svg {
                        path {
                            stroke: $shuttleGrey;
                        }
                    }
                }
            }
        }
    }
}

.primary-dark-color {
    color: var(--primary-dark);
}

.color-secondary {
    color: var(--secondary) !important;
}
