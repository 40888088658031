header {
    .top-header-left {
        display: flex;
        align-items: center;

        .mignify-glass {
            height: 32px;
            width: 32px;
            @include media-breakpoint-up(md) {
                margin-right: 12px;
            }
        }
    }

    .login-message {
        background: var(--primary);

        p {
            padding: 0;
            margin: 0;
            color: $white;
            font-weight: $font-weight-semiBold;

            @include font-sizer(16px, 1);

            @include media-breakpoint-up(md) {
                @include font-sizer(16px, 40px);
            }

            a {
                color: $white;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .organization-change,
        .my-account {
            text-decoration: none;
            display: inline-block;
            margin: 0 12px 0 0;

            svg {
                vertical-align: middle;
                margin: -1px 4px 0 0;

                path {
                    fill: $white;
                }
            }

            &:hover {
                color: $ghost;

                svg {
                    path {
                        fill: $ghost;
                    }
                }
            }
        }
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
}

.logo-wrapper {
    display: flex;
    max-width: 78vw;
    align-items: center;

    a {
        display: inline-block;
        line-height: 0;
        margin: 0 4px;
    }
}
