#loginModal {
    .modal-dialog {
        width: auto;
        max-width: 400px;

        @include media-breakpoint-up(md) {
            max-width: 800px;
        }
    }

    .modal-content {
        border-radius: map_get($spacers, 1);
        border: 0;
    }

    .login-popup-image {
        @include media-breakpoint-up(md) {
            min-height: 531px;
            display: block;
            object-fit: cover;
            border-radius: 0 map_get($spacers, 1) map_get($spacers, 1) 0;
            max-width: 100%;
        }
    }

    .left-login-popup {
        min-height: 483px;
    }

    .login-popup-text-container {
        color: var(--primary);
        text-align: center;
        @include rtl();

        .login-popup-title {
            letter-spacing: -0.6px;
            font-weight: $font600;
            @include font-sizer(30px, 30px);
            @include rtl();
        }

        .login-popup-text {
            @include font-sizer(21px, 21px);
            @include rtl();

            span {
                display: block;

                .phone {
                    display: contents;
                }
            }
        }
    }

    .close {
        position: absolute;
        opacity: 1;
        cursor: pointer;

        svg {
            fill: var(--secondary);
        }
    }

    .login-popup-bottom-text {
        position: absolute;
        width: calc(100% - #{map_get($spacers, 6)});
        right: map_get($spacers, 3);
        bottom: map_get($spacers, 3);
        text-align: right;
        @include font-sizer(17px, 17px);
        @include rtl();
    }

    .info-icon-text {
        display: block;
        padding-right: map_get($spacers, 3);
    }

    .info-icon {
        position: absolute;
        top: 0;
        right: 0;
    }

    .resend-sms-code {
        color: var(--secondary);
        text-decoration: underline;
        font-weight: $font600;
        @include font-sizer(17px, 17px);

        &:hover {
            text-decoration: none;
        }

        &.disabled {
            cursor: default;
            text-decoration: none;
            color: $netural-color-second;
        }
    }

    .login {
        height: 100%;
        display: flex;
    }

    .login-without-error {
        width: 100%;
        text-align: center;
    }

    .login-with-error {
        align-self: center;
        display: flex;
        max-width: 310px;
        margin: 0 auto;
    }

    .popup-login {
        min-width: 211px;
    }

    .invalid-feedback {
        position: absolute;
        right: 24px;
    }
}

#selectOrganizationModal {
    .modal-dialog {
        width: auto;
        max-width: 400px;

        @include media-breakpoint-up(md) {
            max-width: 800px;
        }
    }

    .modal-content {
        border-radius: map_get($spacers, 1);
        border: 0;
    }

    .organization-popup-image {
        @include media-breakpoint-up(md) {
            min-height: 531px;
            display: block;
            object-fit: cover;
            border-radius: 0 map_get($spacers, 1) map_get($spacers, 1) 0;
            max-width: 100%;
        }
    }

    .left-organization-popup {
        min-height: 483px;
    }

    .close {
        position: absolute;
        opacity: 1;
        cursor: pointer;

        svg {
            fill: var(--secondary);
        }
    }

    .organization-popup-text-container {
        color: var(--primary);
        text-align: center;
        @include rtl();

        .organization-popup-title {
            letter-spacing: -0.6px;
            font-weight: $font600;
            @include font-sizer(30px, 30px);
            @include rtl();
        }

        .organization-popup-text {
            @include font-sizer(21px, 21px);
            @include rtl();
        }
    }
}

#inviteDetailsModal {
    .close {
        position: absolute;
        opacity: 1;
        cursor: pointer;
        left: 24px;
    }

    svg {
        fill: var(--secondary);
    }

    h3 {
        letter-spacing: -0.6px;
        color: var(--primary);
        font-weight: $font600;
        @include font-sizer(30px, 1);
    }

    .hello-text {
        margin: 0;
        padding: 0 0 20px;
        @include font-sizer(21px, 104%);
    }

    .main-text {
        margin: 0;
        @include font-sizer(21px, 104%);

        strong {
            font-weight: $font600;
        }
    }

    .red-square {
        display: inline-block;
        background: #f00;
        border-radius: 50%;
        height: 45px;
        padding: 10px;
        text-align: center;
    }
}
