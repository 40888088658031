/* Font Mixins, Extensions */

//-------------------------------
// Font Weights, Styles, Familes
//-------------------------------

@mixin font-family-base($important: null) {
    font-family: $font-family-base $important;
    text-transform: $font-transform-base  $important;
    letter-spacing: $font-letter-spacing-base  $important;
}

@mixin font-family-title($important: null) {
    font-family: $font-family-title $important;
    text-transform: $font-transform-title  $important;
    letter-spacing: $font-letter-spacing-title  $important;
}

@mixin font-extraLight() {
    font-weight: $font200 !important;
}

@mixin font200() {
    font-weight: $font200 !important;
}

@mixin font-light() {
    font-weight: $font300 !important;
}

@mixin font300() {
    font-weight: $font300 !important;
}

@mixin font-normal() {
    font-weight: $font400 !important;
}

@mixin font400() {
    font-weight: $font400 !important;
}

@mixin font-medium() {
    font-weight: $font500 !important;
}

@mixin font500() {
    font-weight: $font500 !important;
}

@mixin font-semiBold() {
    font-weight: $font600 !important;
}

@mixin font600() {
    font-weight: $font600 !important;
}

@mixin font-bold() {
    font-weight: $font700 !important;
}

@mixin font700() {
    font-weight: $font700 !important;
}

@mixin font-extraBold() {
    font-weight: $font800 !important;
}

@mixin font800() {
    font-weight: $font800 !important;
}

@mixin font-italic() {
    font-style: $font-style-italic !important;
}

@mixin font-reset() {
    margin: 0;
    font-family: $font-family-base;
    font-size: $font-size-base;
    font-weight: $font-weight-base;
    line-height: $line-height-base;
    letter-spacing: $font-letter-spacing-base;
    text-transform: $font-transform-base;
    color: $body-color;
    text-align: left;
}

@mixin rtl() {
    direction: rtl;
    unicode-bidi: isolate;
}

@mixin ltr() {
    direction: ltr;
    unicode-bidi: isolate;
}

%font-family-base {
    @include font-family-base(!important);
}

%font-family-title {
    @include font-family-title(!important);
}

%font-light {
    @include font-light();
}

%font-normal {
    @include font-normal();
}

%font-medium {
    @include font-medium();
}

%font-bold {
    @include font-bold();
}

%font-italic {
    @include font-italic();
}

%font-extraLight {
    @include font-extraLight();
}

%font200 {
    @include font200();
}

%font300 {
    @include font300();
}

%font400 {
    @include font400();
}

%font500 {
    @include font500();
}

%font-semiBold {
    @include font-semiBold();
}

%font600 {
    @include font600();
}

%font700 {
    @include font700();
}

%font-extraBold {
    @include font-extraBold();
}

%font800 {
    @include font800();
}

%font-reset {
    @include font-reset();
}

%rtl {
    @include rtl();
}

%ltr {
    @include ltr();
}

.rtl {
    @extend %rtl;
}

.ltr {
    @extend %ltr;
}

.font200 {
    @extend %font200;
}

.font300 {
    @extend %font300;
}

.font400 {
    @extend %font400;
}

.font500 {
    @extend %font500;
}

.font600 {
    @extend %font600;
}

.font700 {
    @extend %font700;
}

.font800 {
    @extend %font800;
}
