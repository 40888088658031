.later {
    color: white;
}

.form-control {
    height: $input-height;
    padding: $input-padding;
    color: $input-color;
    border: $input-border;
    border-radius: $input-border-radius;
    text-align: right;
    @include font-sizer(21px, 21.84px);

    &:focus {
        border-color: var(--primary);
        color: var(--primary-dark);
        outline: none;
        box-shadow: none;
    }

    &.is-invalid {
        border-color: $error;
        color: $error;
        outline: none;
        box-shadow: none;
        background-image: url('../../images/svg/icon-error.svg');
        background-position: top 10px left 16px;
        padding: $input-padding !important;
    }

    &.is-invalid.datepicker-input {
        background-image: none;
    }

    &:disabled {
        background-color: $input-disabled-background;
    }

    &::-webkit-input-placeholder {
        color: $input-color;
        font-family: "Assistant";
        @include font-sizer(21px, 21.84px);
    }

    &.rtl {
        @include rtl();
    }

    &.ltr {
        @include ltr();
    }
}

.form-group {
    &.form-group-filled:not(:focus-within, .hide-validation-marker) {
        position: relative;

        &::before {
            content: '';
            width: 20px;
            height: 14px;
            position: absolute;
            left: 18px;
            top: 43px;
            mask-image: url('../../images/svg/icon-valid.svg');
            display: block;
            background: $fernFrond;
            z-index: 1;
        }
    }

    &.datepicker-input-container {
        &::before {
            mask-image: url('../../images/svg/datepicker-icon.svg');
        }
    }

    &.custom-checkbox {
        &::before {
            display: none;
        }
    }

    .selectric-form-control {
        &.is-invalid {
            .selectric {
                border-color: $error;
                color: var(--primary-dark);

                .button {
                    left: 0;
                    right: auto;
                    background: transparent;

                    &::after {
                        background: $error;
                    }
                }

                .label {
                    color: $error;
                }
            }
        }
    }
}

.invalid-feedback {
    color: $error;
    @include font-sizer(15px, 15px);
    @include rtl();
}

.form-group,
.sort-by-inner-container,
.pdp-select {
    text-align: right;

    &.required {
        .form-control-label {
            &::before {
                content: '' !important;
            }
        }
    }

    &.disabled {
        .form-control-label {
            color: $input-color;
        }
    }

    .selectric {
        height: $input-height;
        border: $input-border;
        border-radius: $input-border-radius;
        background: $input-default-background;

        .button {
            left: 0;
            right: auto;
            background: transparent;

            &::after {
                content: '';
                width: 20px;
                height: 12px;
                position: absolute;
                left: 16px;
                top: 17px;
                mask-image: url('../../images/svg/select-arrow.svg');
                display: block;
                background: var(--secondary);
                margin: 0;
                border: 0;
            }
        }

        .label {
            margin: 0;
            padding: $input-padding;
            height: auto;
            color: $shuttleGrey;
            position: relative;
            top: -2px;
            @include font-sizer(21px, 21.84px);
            @include rtl();
        }
    }

    .selectric-open {
        .button {
            transform: rotate(180deg);

            &::after {
                left: 0;
                top: 10px;
                right: 19px;
            }
        }
    }

    .selectric-is-invalid {
        .selectric {
            border-color: $error;
        }
    }

    .selectric-open,
    .selectric-focus {
        .selectric {
            border-color: var(--primary);
            color: var(--primary-dark);

            .label {
                color: var(--primary-dark);
            }
        }
    }

    .selectric-items li {
        padding: map_get($spacers, 2);
        margin: 4px 0;
        white-space: nowrap;
        background: $white;
        color: var(--primary-dark);
        border-radius: $select-border-radius;
        @include font-sizer(21px, 21.84px);
        @include rtl();

        &:hover,
        &.selected,
        &.highlighted {
            color: var(--primary-dark);
            background: $option-selected-background;
        }

        &.hidden {
            display: none;
        }
    }

    .selectric-scroll {
        padding: map_get($spacers, 3);
        background: $white;
        border-radius: $select-border-radius;
    }

    .selectric-items {
        border-radius: $select-border-radius;
        border: 0;
        box-shadow: $select-box-shadow;
    }

    .selectric-expirationYear,
    .selectric-expirationMonth {
        .label,
        .selectric-items li {
            @include ltr();
        }
    }
}

.pdp-select {
    label {
        display: block;
        color: var(--primary-dark);
        font-weight: $font400;
        margin-bottom: map_get($spacers, 1);
        @include font-sizer(21px, 21.84px);
    }
}

.custom-control {
    padding: 0 map_get($spacers, 4) 0 0;

    .custom-control-label {
        color: var(--primary-dark);
        @include font-sizer(21px, 21.84px);
        @include rtl();
    }
}

.form-control-label {
    display: block;
    margin-bottom: 4px;
    color: var(--primary-dark);
    @include font-sizer(21px, 21.84px);
    @include rtl();
}

.custom-control-input {
    width: 20px;
    height: 20px;
    margin: 0;
    box-shadow: $checkbox-box-shadow;
}

.custom-control-label {
    cursor: pointer;

    &::before {
        width: 18px;
        height: 18px;
        border-radius: $checkbox-border-radius;
        border: $checkbox-border;
        left: auto;
        right: -28px;
    }

    &::after {
        left: auto;
        right: -28px;
    }
}

.custom-control-input:checked ~ .custom-control-label::before {
    border-color: var(--primary);
    background-color: var(--primary);
    box-shadow: $checkbox-box-shadow;
}

.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: $checkbox-box-shadow;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border: $checkbox-border;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    content: '';
    background: $white;
    mask-image: url('../../images/svg/radio-icon.svg');
    position: absolute;
    right: -24px;
    top: 9px;
    width: 12px;
    height: 10px;
    z-index: 2;
}

input.form-control[type='email'],
input.form-control[type='phone'],
input.form-control[type='number'] {
    @include ltr();
}

input.form-control::-webkit-outer-spin-button,
input.form-control::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.selectric-wrapper select {
    direction: rtl;
}

// https://gist.github.com/coltenkrauter/34ca748828ffd351930aaf34aaef6c72
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000000s ease-in-out 0s;
}
