/* _functions.scss: homebrewed and 3rd party SASS functions */

// Import SassyLists, a collection of useful list and map functions. see doc: http://at-import.github.io/SassyLists/documentation/

@import "sassy-lists/dist/SassyLists";

$browserBasePixelSize: 16px;

/// rem - Convert a pixel value to its equivalent rem value
///	@param {number} $size - a pixel || verbal attribute: auto, initial, etcetera.
///	@return {number} a rem value based on a default browser font size of 16px, or fallback to the original number itself

@function rem($size) {
    @if type-of($size) == string or $size == 0 or unit($size) == "rem" {
        @return $size;
    }
    @if unit($size) != "px" {
        @error "The rem() function expects a pixel || verbal attribute: auto, initial, etcetera.";
    }
    $remSize: $size / $browserBasePixelSize;
    @return $remSize * 1rem;
}

/// rem-to-px - Convert the other way: rem value to its equivalent px value
///	@param {number} $size - a rem value
///	@return {number} a px value based on a default browser font size of 16px

@function rem-to-px($size) {
    @return ($size / 1rem) * $browserBasePixelSize;
}

///	vw - Given a target font-size (in px) and a target viewport width (in px), calculates an
/// equivalent vw (scaling) value that will equal the px size at exactly the given screen width.
/// @author Anthony Smith
///	@param {number} $pxsize - a target font size in pixels
///	@param {number} $screenwidth - a target viewport size in pixels
///	@return {number} a vw value that will equal the targeted px value at the targeted viewport.

@function vw($pxsize, $screenwidth) {
    $vwunit: $screenwidth / 100;
    @return $pxsize / $vwunit * 1vw;
}

/// ensure-unit - If a number value is 0, adds 'px' to it.
/// @param {number} $value - if unit value, leave it alone. if unitless 0 value, append 'px'.
/// @return {number} a unit number value

@function ensure-unit($value: null, $unit: "px") {
    $return-value: $value;
    @if $value != null and unit($value) == "" {
        $return-value: #{$value}#{$unit};
    }
    @return $return-value;
}

/// strip-units - Strip unit off of a number value, return just the number
/// @param {number} $value - a unit value to strip
/// @return {number} a unitless number value

@function strip-units($value) {
    @return $value / ($value * 0 + 1);
}

/// Join Lists - Join multiple lists into one
/// Since the builtin join() function in Sass only take 2 arguments, it can only merge 2 lists at a time.
/// The join-lists function below allows you to combine multiple lists together in a cleaner way.
/// @author Anthony Smith
/// @param {arglist} $lists - List chain
/// @return {list} - Merged lists

@function join-lists($lists...) {
    $collection: ();

    @each $list in $lists {
        $collection: join($collection, $list);
    }
    @return $collection;
}

/// Map Collect - String together multiple maps to merge into one map.
/// Since the builtin map-merge function in Sass only take 2 arguments, it can only merge 2 maps at a time.
/// The map-collect function below allows you to combine multiple maps together in a cleaner way.
/// @author Jessica Biggs
/// @param {arglist} $maps - Map chain
/// @return {map} - Merged maps

@function map-collect($maps...) {
    $collection: ();

    @each $map in $maps {
        $collection: map-merge($collection, $map);
    }
    @return $collection;
}

/// Map deep get - fetch deeply nested values from a map, similar to a JSON nested object syntax
/// @author Hugo Giraudel
/// @param {map} $map - Map
/// @param {arglist} $keys - Key chain
/// @return {*} - Desired value
/// @example
/// // given the following map
/// $mymap: (
///     "colors": (
///         "blues": (
///             "turquoise": #40e0d0,
///             "indigo": #4b0082
///         )
///     )
/// );
/// color: map-deep-get($mymap, "colors", "blues", "indigo"); // outputs "#4b0082"

@function map-deep-get($map, $keys...) {
    @each $key in $keys {
        $map: map-get($map, $key);
    }
    @return $map;
}

///	Map has deep get - determine if a map has a deep-get value to return, given a heiracrchy of nested map keys
/// @author Anthony Smith
///	@param {map} $map - a Map
///	@param {arglist} $keys - a Key chain
/// @return {boolean} true if a nested key was found

@function map-has-nested-keys($map, $keys...) {
    @each $key in $keys {
        @if type-of($map) != "map" {
            @return false;
        }
        @if not map-has-key($map, $key) {
            @return false;
        }
        $map: map-get($map, $key);
    }
    @return true;
}

/// Color Diff - determine the relative color difference between two colors in terms of SASS functions for 'saturate', 'lighten', and 'darken'
/// @author Hugo Giraudel
/// @param {color} $a - a color value
/// @param {color} $b - a color value
/// @return {map} a map of color function names and associated values

@function color-diff($a, $b) {
    $sat: saturation($a) - saturation($b);
    $lig: lightness($a) - lightness($b);
    $fn-sat: if($sat > 0, 'desaturate', 'saturate');
    $fn-lig: if($lig > 0, 'darken', 'lighten');

    @return (
        adjust-hue: -(hue($a) - hue($b)),
        #{$fn-sat}: abs($sat),
        #{$fn-lig}: abs($lig)
    );
}

/// Apply Color Diff - apply the color diff determined by the function above, Color Diff, to any starting color value
/// @author Hugo Giraudel
/// @param {color} $color - a starting color value
/// @param {map} $diff - the output of a color-diff function
/// @return {color} a resulting color value after the diff is applied

@function apply-color-diff($color, $diff) {
    @each $function, $value in $diff {
        $color: call(get-function($function), $color, $value);
    }

    @return $color;
}

@mixin rtl() {
    direction: rtl;
    unicode-bidi: isolate;
}
