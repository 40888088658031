@import "base/utilities/swatch";
@import "base/components/productCommon";
@import "base/components/quickView";

.grid-product {
    height: 100%;
    background: $white;
    border-radius: map_get($spacers, 2);
}

.product-tile {
    border: 0;
    margin-bottom: 0;
    overflow: hidden;
    border-radius: map_get($spacers, 2);
    background: $transparent;
    height: 100%;
    transition: box-shadow 0.25s ease-in-out;

    &:hover {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
    }

    .tile-body-with-border {
        height: 100%;
        border: $netural-border-another;
        padding-bottom: 43px;
        border-radius: 0 0 map_get($spacers, 2) map_get($spacers, 2);
        @include media-breakpoint-up(md) {
            border: $netural-border;
            padding-bottom: 61px;
        }
    }

    .tile-body {
        padding: 0;
        position: relative;
        height: calc(100% - 156px);
        color: var(--primary);
        @include media-breakpoint-up(md) {
            height: calc(100% - 246px);
        }

        .color-swatches {
            min-height: 2.25em;

            .product-tile-color-label {
                cursor: pointer;
                font-size: 1em;

                @include media-breakpoint-down(md) {
                    font-size: 0.9375em;
                }

                @include media-breakpoint-down(sm) {
                    font-size: 0.8125em;
                }
            }
        }

        .price {
            font-size: 1.125em;
            margin-bottom: 0;

            @include media-breakpoint-down(md) {
                font-size: 1.0625em;
            }

            @include media-breakpoint-down(sm) {
                font-size: 1em;
            }

            .tiered {
                font-size: 0.875em;

                .value {
                    font-weight: bold;
                }
            }
        }

        .coming-soon-tile {
            text-align: center;
        }

        .ratings {
            font-size: 0.9em;
        }
    }

    .image-container {
        position: relative;
        overflow: auto;
        display: block;
        background: url('../../images/noimagelarge.png');
        background-size: contain;

        .quickview {
            position: absolute;
            bottom: 1rem;
            right: 1rem;

            i {
                &.fa-expand {
                    color: rgba(0, 0, 0, 0.7);
                }

                &.fa-circle {
                    color: rgba(255, 255, 255, 0.7);
                    text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
                }
            }
        }

        .tile-image {
            display: inline-block;
            width: 100%;
            height: 156px;
            aspect-ratio: 1.5 / 1;
            object-fit: cover;
            vertical-align: top;
            border-radius: map_get($spacers, 2) map_get($spacers, 2) 0 0;
            @include media-breakpoint-up(md) {
                height: 246px;
            }
        }
    }

    .swatches {
        a {
            text-decoration: none;
        }
    }

    .swatch-circle {
        // $white will be replaced by color image background via Javascript
        @include swatch(1.8em, $white);
    }

    .dates {
        margin-bottom: 4px;
        @include font-sizer(15px, 15px);
        @include media-breakpoint-up(md) {
            @include font-sizer(17px, 17px);
        }
    }

    .product-location {
        @include font-sizer(15px, 15px);
        @include media-breakpoint-up(md) {
            @include font-sizer(17px, 17px);
        }

        .course-branches {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            padding: 0 15px 0 0;
        }

        .branches-icon {
            display: block;
            margin: -17px 0 0;
        }
    }

    .grey-button {
        color: var(--primary);
        width: 100%;
        background: $input-disabled-background;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        @include rtl();
        @include media-breakpoint-down(sm) {
            border-radius: 8px;
            padding: 4px;
            height: 39px;
            font-weight: $font600;
            overflow: hidden;
            @include font-sizer(12px, 15px);
        }
        @include media-breakpoint-up(md) {
            padding: 3px 10px;
            border-radius: 12px;
            border: $netural-border;
            height: 60px;
            @include font-sizer(17px, 28px);
        }
    }

    .range-first,
    .range-second,
    .range-third {
        color: var(--secondary);
    }

    .range-second {
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .range-third {
        margin: 0 4px;

        @include font-sizer(17px, 17px);
        @include media-breakpoint-down(md) {
            @include font-sizer(15px, 15px);
        }
        @include media-breakpoint-down(sm) {
            order: 2;
            margin: 4px 0 0;
        }

        span {
            font-weight: $font400;
        }
    }

    .range-first {
        margin: 0 4px;
        @include font-sizer(21px, 21px);
        @include media-breakpoint-down(md) {
            letter-spacing: -0.17px;
            margin-top: 4px;
            @include font-sizer(17px, 17px);
        }
        @include media-breakpoint-down(sm) {
            margin: 0;
        }
    }

    .range {
        margin-bottom: 4px;
    }

    .price-bottom-label {
        @include font-sizer(15px, 15px);
    }

    .tile-link-bottom {
        @include media-breakpoint-down(sm) {
            padding-left: 0;
            padding-right: 0;
            border-radius: 0 0 12px 12px;
            position: relative;
            bottom: -1px;
            left: -1px;
            width: calc(100% + 2px);
            max-width: none;
            min-width: auto;
        }

        &:hover {
            @include media-breakpoint-down(sm) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}

#chooseBonusProductModal {
    .modal-footer {
        .container {
            margin-left: 0;
            width: 100%;
            margin-right: 0;
        }
    }

    .select-cbp-container {
        margin-top: auto;
        margin-bottom: auto;
    }

    .product-name-wrapper {
        width: 100%;
    }

    .bonus-quantity,
    .bonus-option {
        margin-top: 0.938em;
    }

    .bonus-quantity-select {
        min-width: 5em;
    }

    .select-bonus-product {
        margin-top: 1em;
    }

    .selected-pid {
        border: 1px solid $grey3;

        .bonus-product-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .bonus-product-price {
        text-align: center;
        margin-top: 1em;
    }
}

.product-name {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    cursor: pointer;

    p {
        width: 100%;
        margin: 0;
        background: var(--primary);
        color: $white;
        height: 38px;
        padding: 4px map_get($spacers, 2);
        font-weight: $font600;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        overflow: hidden;
        @include rtl();
        @include font-sizer(15px, 15px);
        @include media-breakpoint-up(md) {
            font-weight: $font600;
            height: 72px;
            @include font-sizer(21px, 32px);
        }
    }

    p.many-lines {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .hover-text {
        display: none;
    }
}

.product-short-description {
    padding: 0 map_get($spacers, 2) 0  map_get($spacers, 2);
    background: var(--primary);
    color: $white;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 100%;
    overflow: hidden;
    z-index: $z-index-4;
    text-align: center;
    text-overflow: ellipsis;
    transition: height 0.25s ease-in-out, padding 0.25s ease-in-out;
    @include font-sizer(17px, 17px);
    @include rtl();

    &.d-block {
        padding: map_get($spacers, 1) map_get($spacers, 2) 10px  map_get($spacers, 2);
        height: calc(100% - 34px);
    }

    @include media-breakpoint-up(md) {
        &.d-block {
            padding: map_get($spacers, 1) map_get($spacers, 2) 5px  map_get($spacers, 2);
            height: calc(100% - 61px);
        }
    }

    p {
        height: 100%;
        overflow: hidden;
    }

    &.product-short-description-with-price {
        p {
            height: 93%;
        }
    }
}

.tile-button {
    position: absolute;
    width: calc(100% - 2px);
    bottom: 0;
    left: 1px;
    @include media-breakpoint-up(md) {
        width: calc(100% - #{map_get($spacers, 6)});
        left: 0;
        bottom: map_get($spacers, 1);
    }
}
