* {
    box-sizing: border-box;
}

html {
    font-size: $font-size-base;
    font-family: $font-family-sans-serif;
}

body {
    font-size: $font-size-body;
    color: var(--primary);
    padding: 0;
    margin: 0;
}

form {
    font-size: $font-size-body;
}

p {
    margin: 0 0 8px;
    line-height: 1;
}

ul,
ul li {
    list-style: none;
    padding: 0;
    margin: 0;
}

::marker {
    display: none;
}

a {
    text-decoration: none;
}
