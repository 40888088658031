@import "base/components/toastMessage";

path,
a,
button {
    transition: all 0.25s ease-in-out;
}

footer {
    background: var(--primary-dark);

    .footer-email-signup {
        #email-signup-form {
            position: relative;

            .subscribe-input,
            .subscribe-btn {
                color: $white;
                background: none;
                border: 0 none;
                outline: none;
                font-weight: $font700;
                @include font-sizer(25px, 1);
            }

            .subscribe-btn {
                padding: 20px;
                cursor: pointer;
                position: absolute;
                top: 0;

                &:hover {
                    color: var(--secondary-light);
                }
            }

            .subscribe-input {
                border: 1px solid $ghost;
                overflow: hidden;
                border-radius: 16px;
                height: 66px;
                padding: 1px 30px 1px 100px;
                font-weight: $font600;
                width: 100%;
                letter-spacing: -0.75px;

                &.valid {
                    color: var(--secondary-light);
                }

                &.error {
                    color: $error;
                }

                &::placeholder {
                    color: $white;
                }

                &:focus {
                    border: 2px solid $ghost;
                    padding: 0 30px 0 100px;

                    &::placeholder {
                        color: $transparent;
                    }
                }
            }
        }
    }

    .footer-container {
        .footer-item {
            h2,
            a {
                color: $white;
                @include rtl();
            }

            a {
                @include font-sizer(17px, 1);
                @include media-breakpoint-up(md) {
                    @include font-sizer(21px, 1);
                }
            }

            .title {
                margin: 0 0 map_get($spacers, 3) 0;
                font-weight: $font-weight-semiBold;
                @include font-sizer(21px, 1);
                @include media-breakpoint-up(md) {
                    margin: 0 0 map_get($spacers, 2) 0;
                    font-weight: 600;
                    @include font-sizer(25px, 1);
                }
            }

            .menu-footer {
                li {
                    text-align: right;

                    a {
                        transition: text-decoration 0.25s ease-in-out;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    .footer-bottom {
        .social {
            .social-links {
                display: flex;
                width: 100%;
                justify-content: space-between;
                padding-bottom: map_get($spacers, 4);
                @include media-breakpoint-up(md) {
                    width: 208px;
                    justify-content: space-between;
                    padding-bottom: 0;
                }

                li {
                    padding: 0 map_get($spacers, 2);
                    display: flex;
                    width: 25px;
                    height: 25px;
                    box-sizing: content-box;
                    align-items: center;
                    justify-content: center;

                    @include media-breakpoint-up(md) {
                        padding: map_get($spacers, 1);
                        width: 20px;
                        height: 20px;
                    }

                    svg {
                        @include media-breakpoint-up(md) {
                            width: 20px;
                            height: 20px;
                        }
                    }

                    a {
                        display: block;
                        @include media-breakpoint-up(md) {
                            width: 20px;
                            height: 20px;
                        }

                        &:hover {
                            svg {
                                path {
                                    fill: $ghost;
                                }
                            }
                        }
                    }
                }
            }
        }

        .settings {
            text-align: center;

            .settings-inner {
                @include media-breakpoint-up(md) {
                    display: flex;
                    width: 100%;
                    height: 100%;
                    align-items: center;
                    justify-content: center;
                }
            }

            .settings-list {
                li {
                    display: inline-block;
                    margin: 0 12px;

                    a {
                        color: $white;
                        @include rtl();
                        @include font-sizer(15px, 1);
                        @include media-breakpoint-up(md) {
                            transition: color 0.25s ease-in-out;
                            font-weight: $font600;
                        }

                        &:hover {
                            text-decoration: underline;
                            color: $ghost;
                        }
                    }
                }
            }
        }

        .copyright-notice {
            .copyright {
                text-align: right;
                color: $white;
                letter-spacing: -0.28px;
                @include rtl();
                @include font-sizer(14px, 1);

                svg {
                    margin: 0 0 -3px 7px;
                }
            }

            .copyright-notice-inner {
                @include media-breakpoint-up(md) {
                    display: flex;
                    width: 100%;
                    height: 100%;
                    align-items: center;
                    justify-content: flex-end;
                }
            }
        }
    }
}

.email-signup-message {
    @include toast-message();
}

.email-signup-alert {
    @include toast-alert();
}
