@font-face {
    font-family: "Assistant";
    src: url("../fonts/Assistant-ExtraLight.ttf?#iefix");
    src: url("../fonts/Assistant-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Assistant";
    src: url("../fonts/Assistant-Light.ttf?#iefix");
    src: url("../fonts/Assistant-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Assistant";
    src: url("../fonts/Assistant-Regular.ttf?#iefix");
    src: url("../fonts/Assistant-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Assistant";
    src: url("../fonts/Assistant-Medium.ttf?#iefix");
    src: url("../fonts/Assistant-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Assistant";
    src: url("../fonts/Assistant-SemiBold.ttf?#iefix");
    src: url("../fonts/Assistant-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Assistant";
    src: url("../fonts/Assistant-Bold.ttf?#iefix");
    src: url("../fonts/Assistant-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Assistant";
    src: url("../fonts/Assistant-ExtraBold.ttf?#iefix");
    src: url("../fonts/Assistant-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}
