h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h7 {
    &.font-normal { @extend %font-normal; }
    &.font-light { @extend %font-light; }
    &.font-medium { @extend %font-medium; }
    &.font-bold { @extend %font-bold; }
    &.font-italic { @extend %font-italic; }
    &.font300 { @extend %font300; }
    &.font400 { @extend %font400; }
    &.font500 { @extend %font500; }
    &.font600 { @extend %font600; }
    &.font700 { @extend %font700; }
    &.font800 { @extend %font800; }
    &.primary-dark { color: var(--primary-dark); }
    &.primary-light { color: var(--primary-light); }
    &.secondary { color: var(--secondary); }
    &.secondary-light { color: var(--secondary-light); }
    &.secondary-dark { color: var(--secondary-dark); }
}

h1,
.h1 {
    margin: map-get($spacers, 3) 0;
    font-weight: $font700;

    @include media-breakpoint-up(md) {
        margin: map-get($spacers, 5) 0;
    }
}

h2,
.h2 {
    margin: map-get($spacers, 2) 0;
    font-weight: $font600;

    @include media-breakpoint-up(md) {
        font-weight: $font700;
        margin: map-get($spacers, 5) 0;
    }
}

h3,
.h3 {
    color: var(--secondary);
    font-weight: $font600;
    margin-top: map_get($spacers, 3);

    @include media-breakpoint-up(md) {
        margin-top: map_get($spacers, 5);
    }
}

h1,
.h1 {
    @include typography(
        $m-size: $h1-font-size-mobile,
        $m-line: $h1-line-height-mobile,
        $t-size: $h1-font-size-tablet,
        $t-line: $h1-line-height-tablet,
        $size: $h1-font-size-desktop,
        $line: $h1-line-height-desktop,
        $letter: $h1-letter-spacing,
        $t-trans: null
    );
}

h2,
.h2 {
    @include typography(
        $m-size: $h2-font-size-mobile,
        $m-line: $h2-line-height-mobile,
        $t-size: $h2-font-size-tablet,
        $t-line: $h2-line-height-tablet,
        $size: $h2-font-size-desktop,
        $line: $h2-line-height-desktop,
        $letter: $h2-letter-spacing,
        $t-trans: null
    );
}

h3,
.h3 {
    @include typography(
        $m-size: $h3-font-size-mobile,
        $m-line: $h3-line-height-mobile,
        $t-size: $h3-font-size-tablet,
        $t-line: $h3-line-height-tablet,
        $size:   $h3-font-size-desktop,
        $line:   $h3-line-height-desktop,
        $letter: $h3-letter-spacing,
        $t-trans: null
    );
}

.modal-body {
    h3,
    .h3 {
        @include typography(
            $m-size: $h3-font-size-desktop,
            $m-line: $h3-line-height-desktop,
            $t-size: $h3-font-size-desktop,
            $t-line: $h3-line-height-desktop,
            $size:   $h3-font-size-desktop,
            $line:   $h3-line-height-desktop,
            $letter: $h3-letter-spacing,
            $t-trans: null
        );
    }
}

h4,
.h4 {
    @include typography(
        $m-size: $h4-font-size-mobile,
        $m-line: $h4-line-height-mobile,
        $t-size: $h4-font-size-tablet,
        $t-line: $h4-line-height-tablet,
        $size: $h4-font-size-desktop,
        $line: $h4-line-height-desktop,
        $letter: $h4-letter-spacing,
        $t-trans: null
    );
}

h5,
.h5 {
    @include typography(
        $m-size: $h5-font-size-mobile,
        $m-line: $h5-line-height-mobile,
        $t-size: $h5-font-size-tablet,
        $t-line: $h5-line-height-tablet,
        $size: $h5-font-size-desktop,
        $line: $h5-line-height-desktop,
        $letter: $h5-letter-spacing,
        $t-trans: null
    );
}
