.btn-primary,
.btn-outline-primary {
    background: var(--secondary);
    border: 0 none;
    color: $white;
    width: auto;
    max-width: 100%;
    overflow: visible;
    transition: background 0.25s ease-in-out;
    transition: color 0.25s ease-in-out;

    &:not([disabled]):focus,
    &:not([disabled]).focus,
    &:not([disabled]):hover {
        background: var(--secondary-dark);
        border: 0 none;
        box-shadow: none;
    }

    &.disabled,
    &:disabled {
        opacity: 1;
        background-color: $netural-color-second;
        border: 0 none;
        color: $white;
        cursor: not-allowed;

        span {
            cursor: not-allowed;
        }
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled).active:focus,
    &:not(:disabled):not(.disabled):active:focus {
        background: var(--secondary-dark);
        border: 0 none;
        box-shadow: none;
    }
}

.btn {
    cursor: pointer;
    padding: 4px 20px;
    border-radius: map_get($spacers, 1);
    position: relative;
    outline: 0;
    font-weight: $font500;
    text-align: center;
    letter-spacing: -0.17px;
    transition: all 0.25s ease-in-out;
    @include font-sizer(17px, 1);

    &::before {
        content: '';
        width: 0;
        position: absolute;
        transition: opacity 0.25s ease-in-out;
        transition: width 0.25s ease-in-out;
        opacity: 0;
    }

    &:not([disabled]):hover,
    &:not([disabled]):active,
    &:not([disabled]).hovered {
        padding-left: 28px;
        padding-right: 12px;
        cursor: pointer;

        &::before {
            content: '';
            width: 12px;
            height: 11px;
            position: absolute;
            left: 12px;
            top: 7px;
            opacity: 1;
            mask-image: url('../images/svg/buttonArrow.svg');
            background: $white;
        }
    }

    &.btn-lg:not([disabled]):hover,
    &.btn-lg:not([disabled]):active,
    &.btn-lg:not([disabled]).hovered {
        &::before {
            left: 35px;
            top: 13px;
        }
    }

    &.btn-link {
        text-decoration: none;
        border: 0;
        display: inline-block;
        margin: 0;
        height: auto;
        position: relative;

        &.light {
            border: 0;
        }
    }

    &.light {
        color: var(--secondary);
        background: $white;
        border: 1px solid var(--secondary);

        &:not([disabled]):hover,
        &:not([disabled]).hovered,
        &:not([disabled]):active {
            &::before {
                background: var(--secondary);
            }
        }
    }

    &.btn-lg {
        padding: 4px 48px;
        font-weight: $font400;
        box-sizing: border-box;
        min-width: 215px;
        border-radius: 12px;
        @include font-sizer(21px, 27px);

        span {
            letter-spacing: -0.17px;
            font-weight: $font600;
            position: relative;
            top: -2px;
            display: inline-block;
            @include font-sizer(17px, 17px);
        }

        &.light {
            padding: 3px 48px;
        }

        &.back-to-order {
            padding: 4px 24px;
        }
    }

    &.btn-xlg {
        padding: 4px 48px;
        font-weight: $font400;
        box-sizing: border-box;
        min-width: 250px;
        border-radius: map_get($spacers, 2);
        @include font-sizer(21px, 37px);

        span {
            letter-spacing: -0.17px;
            font-weight: $font600;
            position: relative;
            top: -2px;
            display: inline-block;
            @include font-sizer(21px, 21px);
        }

        &.light {
            padding: 3px 48px;
        }
    }

    &.span-arrow {
        span {
            &::before {
                content: '';
                width: 0;
                position: absolute;
                transition: opacity 0.25s ease-in-out;
                transition: width 0.25s ease-in-out;
                opacity: 0;
            }
        }

        &:not([disabled]):hover,
        &:not([disabled]):active,
        &:not([disabled]).hovered {
            padding-right: 12px;

            span {
                position: relative;

                &::before {
                    content: '';
                    width: 12px;
                    height: 11px;
                    left: -16px;
                    top: 4px;
                    opacity: 1;
                    mask-image: url('../images/svg/buttonArrow.svg');
                    background: $white;
                }
            }

            &::before {
                display: none;
            }
        }

        &.light {
            &:not([disabled]):hover,
            &:not([disabled]):active,
            &:not([disabled]).hovered {
                span {
                    &::before {
                        background: var(--secondary);
                    }
                }
            }
        }

        &.btn-xlg {
            &:not([disabled]):hover,
            &:not([disabled]):active,
            &:not([disabled]).hovered {
                padding: 4px 48px;

                span {
                    &::before {
                        top: 7px;
                    }
                }
            }
        }

        &.back-to-order {
            &:not([disabled]):hover,
            &:not([disabled]):active,
            &:not([disabled]).hovered {
                padding-right: 12px;
            }
        }
    }
}

.svg-icon,
.svg-icon-second {
    background: none;
    outline: none;
    border: 0 none;
    border-radius: 0;
    margin: 0;
    padding: 0;

    &.button {
        cursor: pointer;
    }

    svg {
        max-width: 100%;
        max-height: 100%;
    }

    &.fa {
        &::before {
            content: '';
        }
    }
}

.svg-icon {
    svg {
        path { // eslint-disable-line
            stroke: var(--primary);
            transition: stroke 0.25s ease-in-out;
        }
    }

    &:hover {
        svg {
            path { // eslint-disable-line
                stroke: var(--secondary);
            }
        }
    }
}

.svg-icon-second {
    svg {
        fill: var(--primary);
    }

    &:hover {
        svg {
            fill: var(--secondary);
        }
    }

    &.color-secondary {
        svg {
            fill: var(--secondary);
        }
    }

    &.disabled {
        svg {
            fill: $netural-color;
        }
    }
}
